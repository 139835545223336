<template>
  <div class="abroad-page">
    <div
      class="top-banner"
      :style="{ backgroundImage: 'url(' + imageDomain + 'bg.png)' }"
    >
      <div class="title">{{ banner.title }}</div>
      <div class="desc" v-html="banner.subTitle"></div>
      <div class="qr-image-box">
        <img
          class="qr-image"
          :src="imageDomain+'qr.png'"
          alt=""
        />
        <div class="tip">扫码立即免费咨询</div>
      </div>
    </div>

    <div class="subtitle">留学地区</div>
    <Area />

    <!--    服务内容-->
    <ServiceContent />
    <!--    团队-->
    <Flow />

    <ServiceFeatures />
    <Team />


    <div class="subtitle">
      趴趴留学战绩
      <div class="subtitle-time">2017-2024</div>
    </div>
    <img style="width: 100%" :src="imageDomain+'map.png'" alt="" />

    <OfferSection />

    <img style="width: 100%; margin-top: 60px" :src="imageDomain+'code.png'" alt="" />

    <float-bar category="ielts"></float-bar>
    <float-ads></float-ads>

  </div>
</template>

<script>
  import AbroadJs from './Abroad.js'

  export default AbroadJs
</script>

<style lang="less" scoped>
  .abroad-page {
    background: #fff;

    .subtitle {
      font-size: 30px;
      color: #000000;
      text-align: center;
      font-weight: 700;
      line-height: 38px;
      margin: 60px auto 30px;

      .subtitle-time {
        color: #00000099;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
      }
    }

    .top-banner {
      width: 100%;
      height: 400px;
      background-size: cover;
      text-align: center;

      .title {
        padding-top: 76px;
        color: #fff;
        font-size: 36px;
        margin-bottom: 10px;
      }

      .desc {
        color: #fff;
        font-size: 16px;
        margin-bottom: 40px;
      }

      .qr-image-box {
        img {
          width: 100px;
          height: 100px;
          margin-bottom: 10px;
        }

        .tip {
          color: #ffffff;
          text-align: center;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }

  .root {
    background-color: #fff;
  }

  .bannerWrapper {
    position: relative;
    max-width: 1920px;
    min-width: 1440px;
    height: 520px;
    background: url('https://ncdn.papaen.com/assets/image/web/abroad/v2-3efa8e6bfee6d88b5e3d94f8b6faf39f.png') no-repeat;
    background-size: cover;

    @media screen and(max-width: 1920px) {
      max-height: 640px;
    }

    .textWrapper {
      position: absolute;
      top: 150px;
      left: 300px;

      .bannerTitle {
        font-size: 64px;
        font-weight: 700;
        color: #fff;
      }

      .bannerDesc {
        width: 640px;
        margin-top: 30px;
        margin-bottom: 20px;
        line-height: 36px;
        font-size: 24px;
        font-weight: 300;
        color: #fff;
      }

      .buttonWrapper {
        display: flex;
        width: 256px;
        height: 65px;
        border: 2px solid #fff;
        border-radius: 40px;
        line-height: 60px;
        padding: 0 20px;
        font-size: 24px;
        font-weight: 500;
        color: #fff;

        .buttonImg {
          width: 22px;
          height: 10px;
          margin-left: 10px;
          line-height: 50px;
        }
      }
    }
  }

  .fixRight {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 100;
    background: #fff;
    box-shadow: 0 4px 6px 0 rgba(78, 81, 135, 0.08);
  }

  .content {
    min-width: 1440px;
    background: #fff;
    overflow: hidden;
    padding-top: 65px;
  }

  p {
    margin: 0;
  }

  .service {
    margin-top: 100px;
  }

  .planningBrand {
    margin-top: 20px;
    padding-bottom: 40px;
    padding-top: 60px;
    background: url('https://ncdn.papaen.com/assets/image/web/abroad/v2-0701ddbf93e76de3b86121530a1f689a.png') no-repeat;
    background-size: 100% 100%;
  }

  .applicationBrand {
    padding-bottom: 40px;
    padding-top: 40px;
  }
</style>

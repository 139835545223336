<script>
  export default {
    name: 'SectionTitle',
    props: {
      title: {
        type: String,
        default: 'Title'
      }
    }
  }
</script>

<template>
<div class="section-title">
  <p>{{title}}</p>
</div>
</template>

<style scoped lang="less">
  .section-title {
    font-weight: 600;
    font-size: 28px;
    line-height: 29px;
    letter-spacing: 0.005em;
    color: #121212;

    & p {
      text-align: center;
      margin: 0;
    }

    & p:nth-child(2) {
      margin-top: 10px;
    }
  }
</style>

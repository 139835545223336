<template>
  <Swiper ref="mySwiper" :options="swiperOption">
    <SwiperSlide v-for="data in offerData.casesListData" :key="data.photo">
      <div class="teacherCardWrapper">
        <div class="teacherCard">
          <div class="photoWrapper">
            <img
              :src="data.photo"
              alt=""
            />
          </div>
        </div>
      </div>
    </SwiperSlide>
    <div class=" indicator" slot="pagination">
      <div class="arrow arrowLeft "  @click="goPreSlider">
        <svg width="12" height="12" viewBox="0 0 24 24" class="ZDI ZDI--ArrowLeft24" fill="currentColor">
          <path fill-rule="evenodd"
                d="M14.595 3.794a.875.875 0 0 1 .047 1.237L8.192 12l6.452 6.98a.875.875 0 1 1-1.285 1.187l-7.002-7.574a.875.875 0 0 1 0-1.188l7.001-7.564a.875.875 0 0 1 1.236-.048Z"
                clip-rule="evenodd"></path>
        </svg>
      </div>
      <div class="swiper-pagination dot"></div>
      <div class="arrow arrowRight" @click="goNextSlider">
        <svg width="12" height="12" viewBox="0 0 24 24" class="ZDI ZDI--ArrowRight24" fill="currentColor">
          <path fill-rule="evenodd"
                d="M9.516 3.695a.875.875 0 0 1 1.213.248l5.001 7.575a.875.875 0 0 1 0 .964l-5 7.564a.875.875 0 1 1-1.46-.965L13.95 12 9.268 4.908a.875.875 0 0 1 .248-1.213Z"
                clip-rule="evenodd"></path>
        </svg>
      </div>
    </div>
  </Swiper>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'
  import SectionTitle from '@/views/Abroad/modules/SectionTitle.vue'

  export default {
    name: 'OfferSwiper',
    components: {
      SectionTitle,
      Swiper,
      SwiperSlide,
    },
    props: {
      offerData: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        activeIndex: 0,
        swiperOption: {
          slidesPerView: 5,
          autoplay: {
            delay: 4500,
            disableOnInteraction: false,
          },
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet ( index, className ) {
              return `<span class="${className} swiper-pagination-bullet-custom"></span>`
            }
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        },
      }
    },
    computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper
      }
    },
    methods: {
      goPreSlider(){
        this.swiper.slidePrev()
      },
      goNextSlider(){
        this.swiper.slideNext()
      }
    },
  }
</script>

<style lang="less" scoped>
  .casesList {
    padding-top: 40px;

    .tabWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 60px;
    }

    .tabTitle {
      flex: 0 0 auto !important;
      padding: 0 25px !important;
    }

    .tab {
      display: flex;
      align-items: center;
      flex-direction: column;

      .tab-tabs-wrapper {
        display: flex;
        height: 44px;
        overflow: hidden;
        padding: 0 8px;
        position: relative;

        .tab-item {
          color: rgb(129, 137, 151);
          flex: 0 0 auto !important;
          padding: 0 25px !important;
          position: relative;
          display: -webkit-flex;
          display: flex;
          flex: 1 1;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          font-size: 14px;
          line-height: 20px;
          cursor: pointer;

          .tab-title-active {
            color: rgb(46, 50, 56);
            font-weight: 500;
          }
        }

        .tab-item-line {
          position: absolute;
          bottom: 0px;
          left: 0;
          z-index: 1;
          width: 78px;
          transition-duration: 300ms;
          display: inherit;
          height: 3px;
          border-radius: 3px;
          background-color: rgb(63, 69, 255)
        }
      }
    }
  }

  .list {
    width: 978px;
    height: 300px;
    margin: 68px auto 0;
    display: flex;
    flex-direction: column;
  }

  .slidePage {
    height: 209px;
  }

  .slidepage {
    display: flex;
  }

  .swiper {
    height: 100% !important;
  }

  .teacherCardWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .teacherCard {
    border-radius: 20px;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0px 4px 16px 0px #fff;
    width: 1080px;
    height: 290px;

    .photoWrapper {
      height: 290px;
      width: 200px;
      display: flex;
      justify-content: center;
    }

  }

  .indicator {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    position: relative;
    height: 40px;

    .arrow {
      width: 24px;
      height: 24px;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0px 4px 16px 0px #eee;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: absolute;

      & svg {
        fill: #b7b7b7;
      }
    }

    .arrow:hover {
      & svg {
        fill: #646464;
      }
    }
    .arrowLeft{
      left: 380px;
    }
    .arrowRight{
      right: 380px;
    }

    //.dot {
    //  margin: 0 32px;
    //  display: flex;
    //  align-items: center;
    //
    //  :deep(.dotItem) {
    //    margin-right: 10px;
    //    width: 8px;
    //    height: 8px;
    //    border-radius: 50%;
    //    background-color: rgba(100, 100, 100, 0.12);
    //    cursor: pointer;
    //  }
    //
    //  :deep(.dotItem:last-child) {
    //    margin-right: 0 !important;
    //  }
    //
    //  :deep(.dotItem:hover) {
    //    background-color: #b7b7b7;
    //  }
    //}

    :deep(.dotItem-active) {
      background-color: #646464 !important;
    }
  }

  .swiper-pagination {
    :deep(.swiper-pagination-bullet-custom) {
      margin-right: 10px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: rgba(100, 100, 100, 0.12) !important;
      cursor: pointer;
      opacity: 1;
    }

    :deep(.swiper-pagination-bullet-active) {
      background-color: #646464 !important;
    }
  }

</style>

<template>
  <div class="customCourse">
    <section-title title="服务团队" />
    <div class="customCourseContent"></div>
  </div>
</template>

<script>

import SectionTitle from '@/views/Abroad/modules/SectionTitle.vue'

export default {
  name: "Team",
  components: { SectionTitle },
  props: {},
  data() {
    return {
    };
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    on() {},
  },
};
</script>

<style lang="less" scoped>
  .customCourse {
    background: url('https://ncdn.papaen.com/assets/image/web/abroad/v2-171794008cc030e38572fff36959edb4.png') no-repeat;
    background-size: 100%;
    padding-top: 100px;
    aspect-ratio: 384/167;

    .customCourseContent {
      position: relative;
      max-width: 1920px;
      height: 440px;
      margin: 68px auto 0;
    }

    .title {
      font-weight: 600;
      font-size: 28px;
      line-height: 29px;
      letter-spacing: 0.005em;
      color: #121212;

      & p {
        text-align: center;
        margin: 0;
      }

      & p:nth-child(2) {
        margin-top: 10px;
      }
    }

  }
</style>

<template>
  <div class="area-swapper">
    <div class="img-box" v-for="(item, i) of list" :key="i">
      <img :src="item.url" alt="地区" />
      <div class="desc">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
  const domain = process.env.VUE_APP_IMAGE_DOMAIN + 'image/web/abroad/'

export default {
  name: "Area",
  data() {
    return {
      list: [

        {
          url: domain + 'usa.png',
          title: "美国",
        },

        {
          url: domain + 'UK.png',
          title: "英国",
        },
        {
          url: domain + 'Australia.png',
          title: "澳大利亚",
        },
        {
          url: domain + 'hongkong.png',
          title: "中国香港",
        },
        {
          url: domain + 'Singapore.png',
          title: "新加坡",
        },
        {
          url: domain + 'canada.png',
          title: "加拿大",
        },
        {
          url: domain + 'other.png',
          title: "其他国家",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.area-swapper {
  gap: 10px;
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;
  .img-box {
    flex: 1;
    img {
      width: 128px;
      height: 80px;
      border-radius: 8px;
      transition: all 0.3s;
      &:hover {
        transform: scale(1.1);
      }
    }
    .desc {
      color: #000000e6;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      margin-top: 5px;
    }
  }
}
</style>

<template>
  <div class="team service">
    <section-title title="服务特色" />
    <div class="tuandui">
      <div class="charact" v-for="(item, index) in listData" :key="index" :class="{active: activeIndex === index}"
           @click="handleClick(index)"
      >
        <div class="charctTitle" :class="{titleActive: activeIndex === index}">
          {{item.title}}
        </div>
        <div class="desc" v-if="activeIndex === index">{{item.desc}}</div>
        <div class="charactImage" :class="{imgActive: activeIndex === index}">
<!--          <Image :src="item.image"></Image>-->
          <img :src="item.image" style="width: 100%; height: 100%; object-fit: cover"  />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import SectionTitle from '@/views/Abroad/modules/SectionTitle.vue'

  export default {
    name: 'ServiceContent',
    components: { SectionTitle },
    props: {},
    data () {
      return {
        activeIndex: 0,
        listData: [
          {
            title: '公开透明',
            image: 'https://ncdn.papaen.com/assets/image/web/abroad/v2-ea96264c86d38881f7439a70da7796b1.png',
            desc: '申请信息 全公开 申请进度 随时查 奖学金 不抽成',
          },
          {
            title: '师资雄厚',
            image: 'https://ncdn.papaen.com/assets/image/web/abroad/v2-a94b6404e0a5dd61b63690b9192f759a.png',
            desc: '名校背景、经验丰富、出色案例、专人专项、文书内容个性化',
          },
          {
            title: '保障结果',
            image: 'https://ncdn.papaen.com/assets/image/web/abroad/v2-e0e8a73ce47b14281defd8d7bbde5ce2.png',
            desc: '申请成功率接近100%，申请失败可无条件顺延服务或全额退款',
          },
          {
            title: '跟踪密切',
            image: 'https://ncdn.papaen.com/assets/image/web/abroad/v2-2e733dcb70d950434dfcdc43b23d7f3a.png',
            desc: 'VIP专属留学申请群多元服务保障责任到人',
          },
          {
            title: '服务全覆盖',
            image: 'https://ncdn.papaen.com/assets/image/web/abroad/v2-c3998710abffd5a57c1bdf5f021b82df.png',
            desc: '考试规划、背景提升、文书指导、网申递交、材料青送、签证行前指导、就业求职一站式服务',
          },
          {
            title: '数据支持',
            image: 'https://ncdn.papaen.com/assets/image/web/abroad/v2-887465859d49d6d152d719c214d29daa.png',
            desc: '数万学校及专业一键链接，实时更院校专业最新讯息，大数据进校，定位申请强保障',
          },
          {
            title: '平台保障',
            image: 'https://ncdn.papaen.com/assets/image/web/abroad/v2-9ee16c327a2449bd2216195d73ee4aca.png',
            desc: '纽交所和港交所上市公司,平台背书权益大',
          },
        ]
      }
    },
    methods: {
      handleClick(index){
        this.activeIndex = index
      }
    }
  }
</script>

<style lang="less" scoped>
  .team {
    padding-top: 40px;
    background: url('https://ncdn.papaen.com/assets/image/web/abroad/v2-5a0cdfb8c1069dfc78c55b4b578e20cf.png') no-repeat;
    background-size: 100% 100%;
    text-align: left;
  }

  .tuandui {
    margin: 40px auto 0;
    padding-bottom: 111px;
    display: flex;
    width: 1078px;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .charact {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 125px;
    height: 254px;
    line-height: 254px;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-right: 10px;
    background-color: #f0f5ff;
  }

  .active {
    align-items: flex-start;
    width: 274px;
    background-color: #004CD9;
    transition: width 0.2s;
  }

  .charactImage {
    width: 124px;
    height: 183px;
  }

  .imgActive {
    width: 270px;
  }

  .charctTitle {
    margin-top: 40px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #080D66;
  }

  .titleActive {
    margin-left: 20px;
    color: #fff;
  }

  .desc {
    position: absolute;
    top: 70px;
    width: 126px;
    margin-left: 20px;
    line-height: 30px;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
  }
</style>

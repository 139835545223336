export default {
  props: {
    data: {
      type: Object,
      default: {
        title: '',
        subTitle: ''
      }
    }
  },
  data () {
    return {}
  },
  methods: {
    advisory () {
      clickHandler()
    }
  }
}

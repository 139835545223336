<template>
  <div class="casesList">
    <section-title title="名校录取案例展示" />
    <div class="tab">
      <div class="tab-tabs-wrapper">
        <div class="tab-item" v-for="(item,index) in dataList"
        >
          <div class="tab-title" @click="handleChangeTab(index)"
               :class="{'tab-title-active':activeIndex === index}"
          >{{ item.title }}
          </div>
        </div>
        <div class="tab-item-line" :style="{transform: 'translateX('+ lineTransLate+'px) translateX(-50%)'}"
        ></div>
      </div>
      <div class="tab-tabs-content">
        <div class="tab-tab-panel" v-for="(item, index) in dataList">
          <div class="casesList" v-if="activeIndex === index ">
            <div class="list">
              <div class="slidePage">
                <OfferSwiper :offerData="item" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import SectionTitle from '@/views/Abroad/modules/SectionTitle.vue'
  import OfferSwiper from '@/views/Abroad/modules/offer/OfferSwiper.vue'

  export default {
    name: 'OfferSection',
    components: {
      OfferSwiper,
      SectionTitle,
      Swiper,
      SwiperSlide,
    },
    props: {},
    computed: {
      lineTransLate () {
        if (this.activeIndex > 0) {
          const diff = document.querySelectorAll('.tab-item')[ this.activeIndex ].offsetLeft + Math.floor(document.querySelectorAll('.tab-item')[ this.activeIndex ].offsetWidth) / 2
          return diff
        } else {
          return 47
        }
      }
    },
    data () {
      return {
        activeIndex: 0,
        dataList: [
          {
            'title': '美国',
            'casesListData': [
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-5688cb3cc5f1f1799345a498c4b3b0fe.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-04cf0eacf34478dd6d7b9899075100e6.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-db5d61ad4d5906cddac864bf3f3d1849.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-e5ee34506ff2dad7834e279b601c8973.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-7ab4f5e49c234422a9ebdd2fa4e179b9.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-ec66efad32a110eebcb61fd9039e5a31.png'
              }
            ]
          },
          {
            'title': '英国',
            'casesListData': [
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-0b0ec558a15ca3002bd20f64f6bb1906.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-ad64d7517326a1f8914eaab59cada678.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-23af94870e9ce1705873d98a7621cf04.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-caf2b33e73dccbece7367bb46572d2ed.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-91666a9d86c2e205db90ae39dbd43453.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-3d5b928ce4f20e16fb900d9b855ab089.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-00f41b56dfc37221d6655c08aec5fd3e.png'
              }
            ]
          },
          {
            'title': '香港',
            'casesListData': [
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-998e755bf9eef89d65c5a3f933518ab5.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-f8fe22ab04ba8ea2233a502f6b29f368.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-ccceee2b8dba95eaae66136999383077.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-024deb9f69c4389b7c3eee0a0d9227a3.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-5ad4a1a8f6e1f4f0acd5a25788c77e53.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-be3b44779407045b8b5f32b64b7d41cb.png'
              }
            ]
          },
          {
            'title': '澳洲/新西兰',
            'casesListData': [
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-3f2f0cce7d80de3c3e3770d503fc0fff.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-8fa9626da49eb082ff78c832342c0a1d.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-860207f3464ec494eb3af4af1ca02cc9.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-afb43b474124c9071c5e5e61dcd81c1f.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-286c53fba13bb03c7d218feadc077c98.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-5500b5d40874340c0801cf5e58c86560.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-166ad4ca5a773b92afa61ed124267d27.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-9a890d758d39555992bac3f4eae7a9ea.png'
              }
            ]
          },
          {
            'title': '新加坡',
            'casesListData': [
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-d309d71b7a0ed7e6657d67892cf8fa45.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-15e2db915a824506242ce94adbd43810.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-57461c5b19a6b5dc7e48d25625a600d9.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-768221151be720fc46d548ecfe33576b.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-8dca7fde1494686a3eed68c758520239.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-e6521d2188ea82ceb1cb376572dd22f3.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-120c12582d92ad4ce383369cf5eea290.png'
              }
            ]
          },
          {
            'title': '欧洲',
            'casesListData': [
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-9cb205486fdc9f9fda4feccc9d0e5aab.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-e44a07dc92fab094548c8f47db2f28b9.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-55d0f17649253d672244991d4180fc05.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-6733abe566c6f8c3c72d9c89f70affca.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-4bc5bc0bad2c3f9ae216d1c0653b5d44.png'
              },
              {
                'photo': 'https://ncdn.papaen.com/assets/image/web/abroad/v2-78049244e62490f9b236958ae06d6120.png'
              }
            ]
          }
        ]
      }
    },
    methods: {
      handleChangeTab ( index ) {
        this.activeIndex = index
      }
    },
  }
</script>

<style lang="less" scoped>
  .casesList {
    padding-top: 40px;

    .tabWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 60px;
    }

    .tabTitle {
      flex: 0 0 auto !important;
      padding: 0 25px !important;
    }

    .tab {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 68px;

      .tab-tabs-wrapper {
        display: flex;
        height: 44px;
        overflow: hidden;
        padding: 0 8px;
        position: relative;

        .tab-item {
          color: rgb(129, 137, 151);
          flex: 0 0 auto !important;
          padding: 0 25px !important;
          position: relative;
          display: -webkit-flex;
          display: flex;
          flex: 1 1;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          font-size: 14px;
          line-height: 20px;
          cursor: pointer;

          .tab-title-active {
            color: rgb(46, 50, 56);
            font-weight: 500;
          }
        }

        .tab-item-line {
          position: absolute;
          bottom: 0px;
          left: 0;
          z-index: 1;
          width: 78px;
          transition-duration: 300ms;
          display: inherit;
          height: 3px;
          border-radius: 3px;
          background-color: rgb(63, 69, 255)
        }
      }
    }
  }

  .list {
    width: 978px;
    height: 300px;
    margin: 0px auto 0;
    display: flex;
    flex-direction: column;
  }

  .slidePage {
    height: 209px;
  }

  .slidepage {
    display: flex;
  }

  .swiper {
    height: 100% !important;
  }

  .teacherCardWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .teacherCard {
    border-radius: 20px;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0px 4px 16px 0px #fff;
    width: 1080px;
    height: 290px;

    .photoWrapper {
      height: 290px;
      width: 200px;
      display: flex;
      justify-content: center;
    }

  }

  .indicator {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .arrow {
      width: 24px;
      height: 24px;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0px 4px 16px 0px #eee;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      & svg {
        fill: #b7b7b7;
      }
    }

    .arrow:hover {
      & svg {
        fill: #646464;
      }
    }

    .dot {
      margin: 0 32px;
      display: flex;
      align-items: center;

      :deep(.dotItem) {
        margin-right: 10px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: rgba(100, 100, 100, 0.12);
        cursor: pointer;
      }

      :deep(.dotItem:last-child) {
        margin-right: 0 !important;
      }

      :deep(.dotItem:hover) {
        background-color: #b7b7b7;
      }
    }

    :deep(.dotItem-active) {
      background-color: #646464 !important;
    }
  }
</style>

<template>
  <div class="detailBanner" :style="{backgroundImage: 'url('+data.imageUrl+')'}">
    <div class="title">{{data.title}}</div>
    <div class="subTitle" v-html="data.subTitle"></div>
    <div class="action" @click="advisory">
      <div class="button">立即咨询</div>
    </div>
  </div>
</template>

<script>
  import DetailBannerJs from './DetailBanner.js'

  export default DetailBannerJs
</script>

<style lang="less" scoped>
  /*@import "../../assets/style/lib-base";*/

  .detailBanner {
    width: 100%;
    height: 400px;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;

    .title {
      color: #fff;
      font-size: @fontSizeHugeTitle;
      position: absolute;
      top: 20%;
    }

    .subTitle {
      color: #fff;
      font-size: @fontSizeSubTitle;
      position: absolute;
      top: 40%;
    }

    .action {
      position: absolute;
      top: 60%;
      width: 215px;
      height: 40px;
      line-height: 40px;
      background: rgba(255, 255, 255, .2);
      border-radius: 4px;
      color: #fff;
      font-weight: 400;
      cursor: pointer;
    }
  }
</style>
